import { inject } from '@angular/core';
import { HttpEvent, HttpRequest, HttpInterceptorFn, HttpHandlerFn } from '@angular/common/http';
import { AuthService } from '../services/auth.service';
import { Observable, throwError } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { APP_CONFIG, AppConfig } from '../../../app.config';
import { AkeliusIDToken } from '../models/akelius-id-token.model';
import { RequestHelpers } from '../helpers/request.helpers';

export const authInterceptor: HttpInterceptorFn = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn,
): Observable<HttpEvent<unknown>> => {
  const authService = inject(AuthService);
  const appConfig = inject<AppConfig>(APP_CONFIG);

  if (!RequestHelpers.isIntranetRequest(req, appConfig)) {
    return next(req);
  }

  return authService.getTokenSilently$().pipe(
    switchMap((token: string) => {
      return authService
        .getIdToken$()
        .pipe(map<AkeliusIDToken, [string, AkeliusIDToken]>((idToken: AkeliusIDToken) => [token, idToken]));
    }),
    mergeMap(([token, idToken]: [string, AkeliusIDToken]) => {
      const reqWithHeaders = req.clone({
        withCredentials: false,
        setHeaders: {
          Authorization: `Bearer ${token}`,
          'x-akelius-id-token': idToken?.__raw,
        },
      });

      return next(reqWithHeaders);
    }),
    catchError((err) => throwError(err)),
  );
};
