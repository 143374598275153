import { APP_INITIALIZER } from '@angular/core';
import { initializeApp } from '../../app-config';
import { IconService } from '../services/icon.service';

const provideIcons = () => {
  return [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [IconService],
      multi: true,
    },
  ];
};

export default provideIcons;
