import { Component, inject } from '@angular/core';
import {
  Router,
  NavigationEnd,
  RoutesRecognized,
  ActivatedRoute,
  ActivatedRouteSnapshot,
  GuardsCheckEnd,
} from '@angular/router';
import { PathLocation, BreadcrumbDisplayComponent } from './breadcrumb-display.component';
import { TranslationService } from '../../core/services/translation.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Location, AsyncPipe } from '@angular/common';
import { ContextFacadeService } from '../../core/facades/context-facade.service';
import { delay, pluck, take } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { Breakpoints } from '../../../breakpoints';
import { BreakpointObserver } from '@angular/cdk/layout';
import { BehaviorSubject, Observable } from 'rxjs';
import { TenantService } from '../../core/services/tenant.service';

@UntilDestroy()
@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
  standalone: true,
  imports: [BreadcrumbDisplayComponent, AsyncPipe],
})
export class BreadcrumbComponent {
  private router = inject(Router);
  private translationService = inject(TranslationService);
  private activatedRoute = inject(ActivatedRoute);
  private location = inject(Location);
  private contextFacade = inject(ContextFacadeService);
  private titleService = inject(Title);
  private breakpointObserver = inject(BreakpointObserver);
  private tenantService = inject(TenantService);

  pathElements$ = new BehaviorSubject<PathLocation[]>([]);
  rootPathName: string;
  filters: number[] = [];
  translationFilters: number[] = [];
  markSearchTerm: number[];
  isSmallScreen$: Observable<boolean>;

  constructor() {
    const router = this.router;

    this.rootPathName = this.translationService.getTranslationById('core.page.home');

    // this eventlistener comes to late for first page load...
    router.events.pipe(untilDestroyed(this)).subscribe({
      next: (val) => {
        if (val instanceof RoutesRecognized || val instanceof GuardsCheckEnd) {
          this.setBreadcrumbFilters(val.state.root);
        }

        if (val instanceof NavigationEnd) {
          this.contextFacade.breadcrumbPath$.pipe(take(1), delay(0)).subscribe({
            next: (breadcrumbPath) => {
              this.updateBreadcrumb(this.currentLocationPathname(), breadcrumbPath);
            },
          });
        }
      },
    });
    // ... function has to be triggered by hand
    this.setBreadcrumbFilters(this.activatedRoute.snapshot);
    this.contextFacade.breadcrumbPath$.pipe(take(1)).subscribe({
      next: (breadcrumbPath) => {
        this.updateBreadcrumb(this.currentLocationPathname(), breadcrumbPath);
      },
    });

    this.isSmallScreen$ = this.breakpointObserver.observe([Breakpoints.smallScreen]).pipe(pluck('matches'));
  }

  set pathElements(elements: PathLocation[]) {
    this.pathElements$.next(elements);
  }

  updateBreadcrumb(path: string, breadcrumbPath: string | null) {
    // we create a list of breadcrumbs for the current URL, filtered via the route data attributes
    const routePathParts = path ? path.split('/') : [];
    const routeBreadcrumbs = this.getRouteBreadcrumbs(routePathParts);
    // if a context node is loaded, then we check if the context path (e.g. contracts/all) is in the current URL
    // if not, we add this before the route breadcrumbs. This allows us to display the context breadcrumbs for routes
    // like document detail when the user has come from a list, but to not show context breadcrumbs if the user uses
    // the direct document link
    const contextAlreadyInPath = !breadcrumbPath || this.router.url.startsWith(breadcrumbPath);
    const contextElements = contextAlreadyInPath ? [] : BreadcrumbComponent.getNodePathParts(breadcrumbPath);
    const contextBreadcrumbs = this.getContextBreadcrumbs(contextElements);

    // we always add home as the first breadcrumb
    const homeRoute = {
      title: this.translationService.getTranslationById('core.page.home'),
      path: '/',
    };

    const pathElements = [homeRoute, ...contextBreadcrumbs, ...routeBreadcrumbs];
    this.setBrowserTabTitle(pathElements);

    this.pathElements = pathElements;
  }

  // lettings still uses direct file upload about URLs like:
  // /upload/pictures/marketing?565=Lettings&508=a0047b47&552=apartment&551=0017&560=Donau&561=landscape
  // so we have to remove parameters to avoid missing translations message
  private static getNodePathParts(path: string): string[] {
    return path.split('?')[0].split('/') || [];
  }

  private setBrowserTabTitle(pathElements: PathLocation[]) {
    const lastPathElement = pathElements[pathElements.length - 1];
    const lastPathElementPathParts = lastPathElement.path.split('/');
    // do not show "all" as title
    const tabTitle =
      lastPathElementPathParts[lastPathElementPathParts.length - 1] === 'all'
        ? pathElements[pathElements.length - 2].title
        : lastPathElement.title;

    this.tenantService.tenantConfig$.pipe(take(1)).subscribe((tenantConfig) => {
      this.titleService.setTitle(`${tenantConfig.configuration?.features.header.title} - ${tabTitle}`);
    });
  }

  getRouteBreadcrumbs(routePathParts: string[]): Array<{ title: string; path: string }> {
    let tempPath = '';

    return routePathParts.reduce((acc: Array<{ title: string; path: string }>, pathPart, partIndex) => {
      if (pathPart.length) {
        tempPath += '/' + pathPart;

        if (!this.filters.includes(partIndex)) {
          const displayName = decodeURIComponent(pathPart);

          let title: string;

          if (this.markSearchTerm.includes(partIndex)) {
            title = this.getBreadcrumbTranslation('search:' + displayName);
          } else {
            title = this.translationFilters.includes(partIndex)
              ? displayName
              : this.getBreadcrumbTranslation(displayName);
          }

          // do not add element with name already included(could happen after redirect)
          if (!acc.some((part) => title.includes(part.title))) {
            acc.push({
              title: decodeURIComponent(title),
              path: decodeURIComponent(tempPath),
            });
          }
        }
      }

      return acc;
    }, []);
  }

  getContextBreadcrumbs(contextPathParts: string[]): Array<{ title: string; path: string }> {
    return contextPathParts.reduce((array: Array<{ title: string; path: string }>, segment: string) => {
      return !segment
        ? array
        : [
            ...array,
            {
              title: this.getBreadcrumbTranslation(segment),
              path: array.length ? `${array[array.length - 1].path}/${segment}` : `/${segment}`,
            },
          ];
    }, []);
  }

  // search uses : to separate breadcrumb name and search value
  getBreadcrumbTranslation(part: string) {
    const [breadcrumbName, value] = part.split(':');

    const translatedName = this.translationService.getTranslationById(`dynamic.node.tile.${breadcrumbName}`);
    return value ? `${translatedName}:${value}` : translatedName;
  }

  setBreadcrumbFilters(snapshot: ActivatedRouteSnapshot) {
    // we don't update the filters if the route is marked as being an internal path
    if (!snapshot.data.internalPath) {
      this.filters = this.getBreadcrumbFilterConfig(snapshot);
      this.translationFilters = this.getBreadcrumbTranslationFilterConfig(snapshot);
      this.markSearchTerm = this.getMarkSearchTerm(snapshot);
    }
  }

  private getBreadcrumbFilterConfig(snapshot: ActivatedRouteSnapshot) {
    return this.getDataProperty(snapshot, 'breadcrumbFilters');
  }

  private getBreadcrumbTranslationFilterConfig(snapshot: ActivatedRouteSnapshot) {
    return this.getDataProperty(snapshot, 'breadcrumbTranslationFilters');
  }

  private getMarkSearchTerm(snapshot: ActivatedRouteSnapshot) {
    return this.getDataProperty(snapshot, 'markSearchTerm');
  }

  private getDataProperty(snapshot: ActivatedRouteSnapshot, propertyName: string, parentRouteOffset = 0): number[] {
    const filters: number[] = snapshot?.routeConfig?.data?.[propertyName]
      ? snapshot.routeConfig.data[propertyName]
      : [];
    const routeOffset = parentRouteOffset + snapshot.url.length;
    const childFilters = snapshot.firstChild
      ? this.getDataProperty(snapshot.firstChild, propertyName, routeOffset)
      : [];

    // so that the filters in each route are relative to the route they are defined in, we add the offset from the url segments that
    // come before it (plus an extra one for 'home' which isn't a part of the url.
    // e.g. /search/properties/:id has an offset of 3 (home, search and properties), /properties/:id has an offset of 2 (home + properties)
    const offsetFilters = filters.map((filter) => filter + parentRouteOffset + 1);
    return [...offsetFilters, ...childFilters];
  }

  private currentLocationPathname() {
    return this.location.path().split('?')[0];
  }
}
