import { inject } from '@angular/core';
import { HttpEvent, HttpRequest, HttpInterceptorFn, HttpHandlerFn } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap, take } from 'rxjs/operators';
import { TranslationService } from '../services/translation.service';
import { APP_CONFIG, AppConfig } from '../../../app.config';

export const langInterceptor: HttpInterceptorFn = (
  req: HttpRequest<any>,
  next: HttpHandlerFn,
): Observable<HttpEvent<any>> => {
  const translationService = inject(TranslationService);
  const appConfig = inject<AppConfig>(APP_CONFIG);

  return translationService.currentLang$.pipe(
    take(1),
    switchMap((language: string | null) => {
      const langReq =
        req.url.indexOf(appConfig.backend) >= 0
          ? req.clone({
              setHeaders: {
                'x-akelius-language': language || '',
              },
            })
          : req;

      return next(langReq);
    }),
    catchError((err) => throwError(err)),
  );
};
