import { inject } from '@angular/core';
import { HttpEvent, HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { APP_CONFIG, AppConfig } from '../../../app.config';
import { Location } from '@angular/common';

export const normalizeInterceptor: HttpInterceptorFn = (
  req: HttpRequest<any>,
  next: HttpHandlerFn,
): Observable<HttpEvent<any>> => {
  const appConfig = inject<AppConfig>(APP_CONFIG);

  const normalizeUrl = (url: string): string => {
    return Location.stripTrailingSlash(url);
  };

  // if is intranet request
  if (req.url.indexOf(appConfig.backend) !== -1) {
    req = req.clone({
      url: normalizeUrl(req.url),
    });
  }

  return next(req);
};
