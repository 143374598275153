import { Component, Input, OnChanges } from '@angular/core';
import { RouterLink } from '@angular/router';
import { CallbackPipe } from '../../shared/core/pipes/callback/callback.pipe';

export interface PathLocation {
  title: string;
  path: string;
}

export interface BreadcrumbElement {
  path: PathLocation[];
  index: number;
  truncate: boolean;
}

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb-display.component.html',
  styleUrls: ['./breadcrumb-display.component.scss'],
  standalone: true,
  imports: [RouterLink, CallbackPipe],
})
export class BreadcrumbDisplayComponent implements OnChanges {
  @Input() path: PathLocation[] = [];
  @Input() rootPathName: string;
  @Input() showBackButton = false;
  @Input() isSmallScreen = false;

  truncate = false;

  ngOnChanges() {
    this.truncate = this.isSmallScreen;
  }

  isTruncatePlaceholder(element: BreadcrumbElement): boolean {
    return element.path.length > 3 && element.truncate && element.index === 1;
  }

  isPathElement(element: BreadcrumbElement): boolean {
    return (
      element.path.length <= 3 || !element.truncate || element.index === 0 || element.index >= element.path.length - 2
    );
  }

  onClickPlaceholder() {
    this.truncate = !this.truncate;
  }
}
