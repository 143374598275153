import { Injectable, inject } from '@angular/core';
import { NotificationService } from './notification.service';
import { TranslationService } from './translation.service';
import { CheckVersionService } from './check-version.service';
import { WINDOW_OBJECT } from '../../injection-tokens/injection-tokens';
import { ActionButtonType } from '../../shared/core/components/action-bar/action-bar.component';
import { NotificationMessageType } from '../../shared/core/components/notification/notification.component';

export interface Release {
  version: string;
  releaseNotesLink: string;
}

@Injectable({
  providedIn: 'root',
})
export class ReleaseService {
  private window = inject<Window>(WINDOW_OBJECT);
  private translationService = inject(TranslationService);
  private notificationService = inject(NotificationService);
  private checkVersionService = inject(CheckVersionService);

  showingReleasePopup = false;

  initialize() {
    this.checkVersionService.updateAvailable$.subscribe({
      next: (updateAvailable) => {
        if (updateAvailable && !this.showingReleasePopup) {
          this.showReloadNotification();
        }
      },
    });
  }

  showReloadNotification() {
    this.showingReleasePopup = true;

    this.notificationService
      .showCustomNotification<boolean>('release.heading.newReleaseAvailable', {
        message: this.translationService.getTranslationById('release.message.newRelease'),
        type: NotificationMessageType.info,
        buttons: [
          [
            {
              text: this.translationService.getTranslationById('release.button.continueWorking'),
              type: ActionButtonType.neutral,
              value: false,
            },
            {
              text: this.translationService.getTranslationById('release.button.updateNow'),
              type: ActionButtonType.primary,
              value: true,
            },
          ],
        ],
      })
      .subscribe({
        next: (result) => {
          if (result) {
            this.window.location.reload();
          }
          this.showingReleasePopup = false;
        },
      });
  }
}
