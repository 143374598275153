import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { AuthService } from '../../../../core/services/auth.service';
import { GlobalLoadingIndicatorService } from '../../../../core/services/global-loading-indicator.service';
import { ActivatedRoute } from '@angular/router';
import { MatButton } from '@angular/material/button';
import { AsyncPipe } from '@angular/common';
import { STORAGE_OBJECT, URL_TENANT_ID, WINDOW_OBJECT } from '../../../../injection-tokens/injection-tokens';

@Component({
  templateUrl: './tenant-switch.component.html',
  styleUrls: ['./tenant-switch.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatButton, AsyncPipe],
})
export class TenantSwitchComponent {
  private loadingIndicatorService = inject(GlobalLoadingIndicatorService);

  storage = inject(STORAGE_OBJECT);
  window = inject(WINDOW_OBJECT);
  urlTenantId = inject(URL_TENANT_ID);
  activatedRoute = inject(ActivatedRoute);
  authService = inject(AuthService);

  redirectUrl = this.activatedRoute.snapshot.queryParamMap.get('redirectUrl');
  activeTenant$ = this.authService.getActiveTenant$();

  constructor() {
    this.loadingIndicatorService.setLoading(false);
  }

  proceedToResource() {
    return this.authService.getTenants$().subscribe((allowedTenants) => {
      const urlTenant = allowedTenants.find((tenantOption) => tenantOption.id === this.urlTenantId);
      const redirectPath = this.redirectUrl?.replace(`/t/${urlTenant?.id}/`, '/');
      this.authService.login({ redirectPath, organisationId: urlTenant?.id });
    });
  }
}
