import { Injectable, inject } from '@angular/core';
import { Observable, timer } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { URL_TENANT_ID } from '../../injection-tokens/injection-tokens';

@Injectable({
  providedIn: 'root',
})
export class CheckTenantMismatchService {
  private authService = inject(AuthService);
  private urlTenantId = inject(URL_TENANT_ID);

  readonly TIMEOUT = 1000 * 10;
  hasTenantMismatch: Observable<boolean>;

  constructor() {
    const urlTenantId = this.urlTenantId;

    this.hasTenantMismatch = timer(0, this.TIMEOUT).pipe(
      switchMap(() => this.authService.getActiveTenant$()),
      map((activeTenant) => {
        return urlTenantId !== undefined && activeTenant !== this.urlTenantId;
      }),
      filter((hasTenantMismatch) => hasTenantMismatch),
      take(1),
    );
  }
}
