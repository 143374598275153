<div class="breadcrumbs">
  <nav class="link-list">
    @for (pathElement of path; track pathElement; let idx = $index) {
      @if (idx < path.length - 1) {
        @if ({ path: path, index: idx, truncate: truncate } | callback: isTruncatePlaceholder) {
          <span
            class="truncate-placeholder"
            (click)="onClickPlaceholder()"
            (keydown.enter)="onClickPlaceholder()"
            [tabindex]="0"
            >...</span
          >
          <i class="material-icons icon">chevron_right</i>
        }
        @if ({ path: path, index: idx, truncate: truncate } | callback: isPathElement) {
          <a [routerLink]="pathElement.path">{{ pathElement.title }}</a>
          <i class="material-icons icon">chevron_right</i>
        }
      }
    }
  </nav>

  <div class="title-holder">
    <h1 class="page-title" data-test-id="page-title">
      @if (showBackButton && path.length > 0) {
        <button class="btn-none">
          <i class="material-icons back-button">chevron_left</i>
        </button>
      }
      <span class="page-title-text">{{ path[path.length - 1] ? path[path.length - 1].title : rootPathName }}</span>
    </h1>
  </div>
</div>
