import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, CanActivateFn } from '@angular/router';
import { Observable, of } from 'rxjs';
import { TranslationService } from '../services/translation.service';
import { catchError, finalize, map, tap } from 'rxjs/operators';
import { NotificationService } from '../services/notification.service';
import { GlobalLoadingIndicatorService } from '../services/global-loading-indicator.service';
import { ErrorHandler } from '@angular/core';

export const translationGuard: CanActivateFn = (
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
  const router = inject(Router);
  const translationService = inject(TranslationService);
  const notificationService = inject(NotificationService);
  const loadingIndicatorService = inject(GlobalLoadingIndicatorService);
  const errorHandlerService = inject(ErrorHandler);

  return translationService.loadTranslations().pipe(
    catchError((error) => {
      router.navigateByUrl('/app-unavailable');
      errorHandlerService.handleError(error);
      return of(false);
    }),
    tap({
      next: (fallbackUsed) => {
        if (fallbackUsed) {
          notificationService.showErrorDialog('core.error.usingFallbackTranslations');
        }
      },
    }),
    map((_) => true),
    finalize(() => loadingIndicatorService.setLoading(false)),
  );
};
