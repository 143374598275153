import { ErrorHandler, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { NotificationService } from '../services/notification.service';
import { ContextFacadeService } from '../facades/context-facade.service';
import { FEATURE_TEMPLATE_NAME_MAPPING } from '../models/module-template-name-mapping.config';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { LeafNode } from '../models/node-data.interfaces';
import { TemplateNameType } from '../models/template-name.enum';
import { NotFoundError } from '../common/not-found-error';

export const dynamicRouteGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
): Observable<boolean | UrlTree> => {
  const router = inject(Router);
  const contextFacadeService = inject(ContextFacadeService);
  const notificationService = inject(NotificationService);
  const errorHandlerService = inject(ErrorHandler);

  const urlWithoutParams = state.url.split('?')[0];

  return contextFacadeService.context$.pipe(
    take(1),
    switchMap((context: LeafNode) => {
      return context?.path === urlWithoutParams ? of(context) : contextFacadeService.loadNodes(state.url, true);
    }),
    switchMap((context: LeafNode) => {
      if (context?.template?.name && FEATURE_TEMPLATE_NAME_MAPPING[context.template.name as TemplateNameType]) {
        const path = FEATURE_TEMPLATE_NAME_MAPPING[context.template.name as TemplateNameType].path;
        const urlTree = router.parseUrl(path);
        urlTree.queryParams = { ...route.queryParams, origin: state.url };
        return of(urlTree);
      }
      return of(true);
    }),
    catchError((error) => {
      console.error('error', error);
      return contextFacadeService.context$.pipe(
        take(1),
        map((context) => {
          if (error instanceof NotFoundError) {
            if (context) {
              notificationService.showErrorDialog('core.error.pathNotFound');
              return false;
            } else {
              return router.parseUrl('/');
            }
          } else {
            errorHandlerService.handleError(error);
            if (context) {
              notificationService.showErrorDialog('core.error.navigationFailed');
              return false;
            } else {
              router.navigateByUrl('/app-unavailable', { skipLocationChange: true }).then();
              return false;
            }
          }
        }),
      );
    }),
  );
};
