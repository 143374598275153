<h1>organisation switch</h1>
<p>
  You are trying to open a resource in organisation <b>{{ urlTenantId }}</b
  >.
</p>

<p>
  Clicking proceed will end all open sessions for organisation <b>{{ activeTenant$ | async }}</b>
</p>

<button
  class="action-btn"
  mat-flat-button
  color="primary"
  data-test-btn="proceedToResource"
  (click)="proceedToResource()"
>
  proceed
</button>
