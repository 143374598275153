import { inject } from '@angular/core';
import { HttpEvent, HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { TenantService } from '../services/tenant.service';
import { catchError, switchMap, take } from 'rxjs/operators';
import { APP_CONFIG, AppConfig } from '../../../app.config';
import { RequestHelpers } from '../helpers/request.helpers';

export const tenantInterceptor: HttpInterceptorFn = (
  req: HttpRequest<any>,
  next: HttpHandlerFn,
): Observable<HttpEvent<any>> => {
  const tenantService = inject(TenantService);
  const appConfig = inject<AppConfig>(APP_CONFIG);

  return tenantService.activeTenant$.pipe(
    take(1),
    switchMap((tenantId) => {
      const headerValue = tenantId;
      const newReq =
        headerValue &&
        (RequestHelpers.isIntranetRequest(req, appConfig) || RequestHelpers.isStaffRequest(req, appConfig))
          ? req.clone({
              setHeaders: {
                'x-akelius-tenant': headerValue,
              },
            })
          : req;
      return next(newReq);
    }),
    catchError((err) => throwError(err)),
  );
};
