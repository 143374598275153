<h1>access denied</h1>
<p>
  You have tried to access a resource
  @if (urlTenantId) {
    for <b>{{ urlTenantId }}</b>
  }
  but you do not have the required access.
</p>
<p>
  If you need access
  @if (urlTenantId) {
    to <b> {{ urlTenantId }}</b>
  }
  , please contact your Service Desk.
</p>

@if (urlTenantId && (isAuthenticated$ | async)) {
  <button class="action-btn" mat-flat-button data-test-btn="home" (click)="goToHome()">home</button>
}
@if (isAuthenticated$ | async) {
  <button mat-flat-button color="primary" data-test-btn="logout" (click)="logout()">log out</button>
}
