import { AfterViewInit, ChangeDetectionStrategy, Component, HostListener, inject } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { GlobalLoadingIndicatorService } from '../core/services/global-loading-indicator.service';
import { delay, filter } from 'rxjs/operators';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  Event,
  NavigationEnd,
  Router,
  RouterOutlet,
  Scroll,
} from '@angular/router';
import { AsyncPipe, ViewportScroller } from '@angular/common';
import { WINDOW_OBJECT } from '../injection-tokens/injection-tokens';

const isScrollEvent = (event: Event): event is Scroll => {
  return event instanceof Scroll;
};

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatProgressSpinner, RouterOutlet, AsyncPipe],
})
export class AppComponent implements AfterViewInit {
  private window = inject<Window>(WINDOW_OBJECT);
  router = inject(Router);
  viewportScroller = inject(ViewportScroller);
  activatedRoute = inject(ActivatedRoute);
  private loadingIndicatorService = inject(GlobalLoadingIndicatorService);

  @HostListener('window:resize', ['$event'])
  resizeContent(_event: Event) {
    this.updateViewSize();
  }

  loading$ = this.loadingIndicatorService.loading$.pipe(delay(0)); // without delay, change detection isn't triggered

  constructor() {
    this.loadingIndicatorService.setLoading(true);

    this.router.events.pipe(filter(isScrollEvent)).subscribe({
      next: (e: Scroll) => {
        if (e.position) {
          // backward navigation
          setTimeout(() => {
            if (e?.position) {
              this.viewportScroller.scrollToPosition(e.position);
            }
          }, 0);
        } else if (e.anchor) {
          // anchor navigation
          this.viewportScroller.scrollToAnchor(e.anchor);
        } else {
          // forward navigation
          if (!(e.routerEvent instanceof NavigationEnd)) {
            return;
          }
          if (this.getCombinedRouteData(this.activatedRoute.snapshot).maintainScrollPosition !== true) {
            this.viewportScroller.scrollToPosition([0, 0]);
          }
        }
      },
    });
  }

  ngAfterViewInit() {
    this.updateViewSize();
  }

  private updateViewSize() {
    // window.innerHeight is an accurate representation of the viewport, taking into account address/menu bars on
    // mobile devices
    this.window.document.documentElement.style.setProperty('--app-height', `${this.window.innerHeight}px`);
  }

  private getCombinedRouteData(snapshot: ActivatedRouteSnapshot): any {
    // Base case: if there's no child, return the current route's data
    if (!snapshot.firstChild) {
      return snapshot.data;
    }

    // Recursive case: merge current route's data with child's data
    const childData = this.getCombinedRouteData(snapshot.firstChild);
    return { ...childData, ...snapshot.data };
  }
}
