import { inject } from '@angular/core';
import { HttpEvent, HttpRequest, HttpInterceptorFn, HttpHandlerFn } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AuthService } from '../../../core/services/auth.service';
import { catchError, mergeMap } from 'rxjs/operators';
import { APP_CONFIG, AppConfig } from '../../../../app.config';

export const staffAuthInterceptor: HttpInterceptorFn = (
  req: HttpRequest<any>,
  next: HttpHandlerFn,
): Observable<HttpEvent<any>> => {
  const authService = inject(AuthService);
  const config = inject<AppConfig>(APP_CONFIG);

  const isBackendRequest = (url: string): boolean => {
    const staffAppConfig = config.staffApp;
    return (
      url.indexOf(staffAppConfig.backend.rest) >= 0 ||
      url.indexOf(staffAppConfig.backend.gql) >= 0 ||
      url.indexOf(staffAppConfig.media) >= 0
    );
  };

  if (isBackendRequest(req.url)) {
    return authService.getTokenSilently$().pipe(
      mergeMap((token) => {
        const tokenReq = req.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`,
          },
        });
        return next(tokenReq);
      }),
      catchError((err) => throwError(err)),
    );
  } else {
    return next(req);
  }
};
