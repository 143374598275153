import { APP_CONFIG, AppConfig } from '../../../app.config';
import { STORAGE_OBJECT, URL_TENANT_ID, WINDOW_OBJECT } from '../../injection-tokens/injection-tokens';
import { getBaseHref, getTenantId, storageFactory, windowFactory } from '../../app-config';
import { APP_BASE_HREF } from '@angular/common';

const provideEnvironment = (config: AppConfig) => {
  return [
    { provide: APP_CONFIG, useValue: config },
    { provide: WINDOW_OBJECT, useFactory: windowFactory },
    { provide: STORAGE_OBJECT, useFactory: storageFactory },
    { provide: APP_BASE_HREF, useFactory: getBaseHref },
    { provide: URL_TENANT_ID, useFactory: getTenantId },
  ];
};

export default provideEnvironment;
