import { AuthClientInjectable } from '../injection-tokens/auth-client.token';
import { auth0ClientFactory } from '../services/auth.service';
import { WINDOW_OBJECT } from '../../injection-tokens/injection-tokens';
import { APP_CONFIG } from '../../../app.config';

const provideAuth0 = () => {
  return [
    {
      provide: AuthClientInjectable,
      useFactory: auth0ClientFactory,
      deps: [WINDOW_OBJECT, APP_CONFIG],
    },
  ];
};

export default provideAuth0;
