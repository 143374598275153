import { HttpLink } from 'apollo-angular/http';
import { AppConfig } from '../../../app.config';
import { AuthService } from '../../core/services/auth.service';
import { setContext } from '@apollo/client/link/context';
import { EmptyError, lastValueFrom } from 'rxjs';
import { ApolloLink, InMemoryCache } from '@apollo/client/core';
import { inject } from '@angular/core';

export function generateApolloConfig(appConfig: AppConfig) {
  return () => {
    const httpLink = inject(HttpLink);
    const authService = inject(AuthService);

    const basic = setContext((_operation, _context) => {
      return {
        headers: {
          Accept: 'charset=utf-8',
        },
      };
    });

    const auth = setContext(async () => {
      let token = '';

      try {
        token = await lastValueFrom(authService.getTokenSilently$());
      } catch (err) {
        if (err instanceof EmptyError) {
          console.warn('source did not emit');
        }
      }

      return {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
    });

    const uri = appConfig.staffApp.backend.gql;

    const link = ApolloLink.from([basic, auth, httpLink.create({ uri })]);
    const cache = new InMemoryCache();

    return {
      link,
      cache,
    };
  };
}
