import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { StorageObject } from '../../../../core';
import { AuthService } from '../../../../core/services/auth.service';
import { GlobalLoadingIndicatorService } from '../../../../core/services/global-loading-indicator.service';

import { filter, take } from 'rxjs/operators';
import { MatButton } from '@angular/material/button';
import { AsyncPipe } from '@angular/common';
import { STORAGE_OBJECT, URL_TENANT_ID, WINDOW_OBJECT } from '../../../../injection-tokens/injection-tokens';

@Component({
  templateUrl: './access-unauthorised.component.html',
  styleUrls: ['./access-unauthorised.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatButton, AsyncPipe],
})
export class AccessUnauthorisedComponent {
  private auth = inject(AuthService);
  private loadingIndicatorService = inject(GlobalLoadingIndicatorService);

  storage = inject(STORAGE_OBJECT) as StorageObject;
  window = inject(WINDOW_OBJECT) as Window;
  urlTenantId = inject(URL_TENANT_ID) as string;

  isAuthenticated$ = this.auth.isAuthenticated$;

  constructor() {
    this.loadingIndicatorService.setLoading(false);
  }

  goToHome() {
    return this.auth
      .getTenants$()
      .pipe(
        take(1),
        filter((tenants) => !this.urlTenantId || !tenants.some((tenantOption) => tenantOption.id === this.urlTenantId)),
      )
      .subscribe((tenants) => {
        this.window.location.href = `/t/${tenants[0].id}`;
      });
  }

  logout() {
    this.auth.logout();
  }
}
