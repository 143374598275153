import { Injectable, inject } from '@angular/core';
import { NotificationService } from './notification.service';
import { AuthService } from './auth.service';
import { CheckTenantMismatchService } from './check-tenant-mismatch.service';
import { WINDOW_OBJECT } from '../../injection-tokens/injection-tokens';

@Injectable({
  providedIn: 'root',
})
export class TenantMismatchService {
  private window = inject<Window>(WINDOW_OBJECT);
  private notificationService = inject(NotificationService);
  private checkTenantMismatchService = inject(CheckTenantMismatchService);
  private authService = inject(AuthService);

  showingPopup = false;

  initialize() {
    this.checkTenantMismatchService.hasTenantMismatch.subscribe({
      next: (hasTenantMismatch) => {
        if (hasTenantMismatch && !this.showingPopup) {
          this.showNotification();
        }
      },
    });
  }

  showNotification() {
    this.showingPopup = true;

    this.notificationService.showErrorDialog('tenantMismatch.message').subscribe({
      next: (result) => {
        if (result) {
          this.authService.getActiveTenant$().subscribe({
            next: (tenantId) => {
              this.window.location.href = `/t/${tenantId}`;
            },
          });
          this.showingPopup = false;
        }
      },
    });
  }
}
