import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideFormly } from './app/shared/functional/ui-formly/ui-formly.module';
import { AppComponent } from './app/app/app.component';
import { provideRouter } from '@angular/router';
import { appRoutes } from './app/app.routes';
import { normalizeInterceptor } from './app/core/interceptors/normalize.interceptor';
import { authInterceptor } from './app/core/interceptors/auth.interceptor';
import { langInterceptor } from './app/core/interceptors/lang.interceptor';
import { tenantInterceptor } from './app/core/interceptors/tenant.interceptor';
import { staffAuthInterceptor } from './app/features/staff-app/interceptors/auth.interceptor';
import { provideApollo } from 'apollo-angular';
import { generateApolloConfig } from './app/features/staff-app/apolloProvider';
import { GlobalErrorHandlerProvider } from './app/core/services/global-error-handler.service';
import provideMaterialConfig from './app/core/providers/material.poviders';
import provideAuth0 from './app/core/providers/auth0.providers';
import provideEnvironment from './app/core/providers/environmentSetup.providers';
import provideIcons from './app/core/providers/icons.providers';
import { CustomUrlSerializerProvider } from './app/core/services/url-serializer';

const requestInit: RequestInit = {
  cache: 'no-cache',
};

fetch('/assets/app-config/config.json', requestInit)
  .then((res) => res.json())
  .then((config) => {
    console.info('Intranet running with config:', config);

    if (environment.production) {
      enableProdMode();
    }

    bootstrapApplication(AppComponent, {
      providers: [
        CustomUrlSerializerProvider,
        GlobalErrorHandlerProvider,
        provideIcons(),
        provideFormly(),
        provideMaterialConfig(),
        provideEnvironment(config),
        provideAuth0(),
        provideHttpClient(
          withInterceptors([
            authInterceptor,
            staffAuthInterceptor,
            langInterceptor,
            normalizeInterceptor,
            tenantInterceptor,
          ]),
        ),
        provideAnimations(),
        provideRouter(appRoutes),
        provideApollo(generateApolloConfig(config)),
      ],
    }).catch((err) => console.error(err));
  });
